<template>
  <div class="paishui">
    <div class="jieshao mt20">
      建立一套符合实际的、科学合理的中心城区排水应用管理体系，全面展现城市当前排水运行状态，实时监控各类排水事件，预警排水风险，清晰掌握各类应急资源状态、以多种手段实时辅助应急决策指挥。
      <br />
      以排水数字化建设为契机，以公共基础数据、GIS数据为基础，依托移动终端、水力模拟、在线监测和无线通信等先进技术，通过数据建模、数据分析等手段，构建一个数字化、可视化、智能化的排水应用管理平台，实现（感知、分析、服务、指挥、决策）五位一体智能化监测预警，为实时了解中心城区排水运行状况，处理突发事件，提升中心城区排水业务处理效率及综合指挥调度能力。
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/fangan/paishui.png" alt="" />
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/fangan/paishui-1.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.paishui {
  width: 870px;
  padding-bottom: 60px;
  .img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .jieshao {
    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #181818;
    margin-top: 20px;
  }
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
}
</style>